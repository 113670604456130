import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NotFound } from 'cccisd-laravel-boilerplate';
import _find from 'lodash/find';
import { findNav } from 'cccisd-laravel-appdefs';
import Register from '../../pages/Register';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export default withRouter(
    class AuthRedirect extends React.Component {
        static propTypes = {
            location: PropTypes.object,
            match: PropTypes.object,
            history: PropTypes.object,

            /**
             * This components also handles showing the 404 page.
             * We pass this prop when we know we want to show the 404 page
             */
            isComponentOnly: PropTypes.bool,
        };

        render() {
            if (!Fortress.auth()) {
                return <Register />;
            }

            if (Fortress.auth()) {
                let nav = findNav(AppDefs.navs, AppDefs.routes);
                let homepageRoute = _find(AppDefs.routes, r => r.handle === nav.homepage);

                if (this.props.isComponentOnly) {
                    return <NotFound />;
                }
                if (homepageRoute) {
                    return (
                        <Redirect
                            to={{
                                pathname: homepageRoute.url,
                            }}
                        />
                    );
                }
                return (
                    <Redirect
                        to={{
                            pathname: '/',
                        }}
                    />
                );
            }

            return null;
        }
    }
);
