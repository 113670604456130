import React from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { Html } from 'cccisd-wysiwyg';

import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import style from './style.css';
import UCFLogo from './images/UCF.png';
import Logo3C from './images/3CLogo.png';

const CorrectAnswers = props => {
    const { firstPage, secondPage } = props.settings;
    const Header = () => {
        return (
            <div className={style.header}>
                <div className={style.title}>
                    <h1>Preparing for Trauma Management Therapy</h1>
                </div>
                <div className={style.module}>
                    <p>Module</p>
                    <span>4</span>
                </div>
            </div>
        );
    };
    const Footer = () => {
        const year = new Date().getFullYear();
        return (
            <>
                <hr style={{ borderColor: '#666666', margin: 0 }} />
                <footer>
                    <img src={UCFLogo} alt="UCF Logo" width="182px" height="86px" />
                    <span>www.traumamanagementtherapy.com</span>
                    <img src={Logo3C} alt="3C Logo" width="137.5px" height="25px" />
                    <span>&copy; {year}</span>
                </footer>
            </>
        );
    };
    return (
        <Report>
            <Page>
                <Header />
                <div className={style.content}>
                    <Html content={firstPage} />
                </div>
                <Footer />
            </Page>
            <Page>
                <Header />
                <div className={style.content}>
                    <Html content={secondPage} />
                </div>
                <Footer />
            </Page>
        </Report>
    );
};

CorrectAnswers.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
};

// reportTemplatePlayer wraps the Report Player with the Filter Bar. This is also where the FilterBar lives and you define your filters. The filters are passed to the Player and Widgets.
export default reportTemplatePlayer({
    filterFields: [],
})(CorrectAnswers);
