import React from 'react';
import { Field, CccisdTextarea } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    defaultText: `One early summer night, you receive a call that an 11-month-old infant is non-responsive and in critical condition. You're riding in the rescue truck, while Firefighter Smith is riding in the fire engine. You are rushing to get there. You can see the four lanes. You're thinking of  everything you'll need to do when you arrive at the scene and are getting prepared.

The truck is passing through busy, urban areas. You see the motel. You pull in behind another fire engine and you can see the flashing lights. The motel lights illuminate the hallway with an amber glow and you can see the yellow doors. You have been here before. You enter the room and see the little boy in the bathroom to the left. You are the only paramedic on scene. You think to yourself, "I need to get ready to work." You know CPR with infants is more difficult. You are going to get the airway cleared. Firefighter Smith and you put the infant on the stretcher and run back to the truck. You get into the truck and are sitting in the captain's chair with the head of the stretcher at your feet. To your right, you can see the cabinet with medical equipment.

Doors slam shut and the truck drives to the nearest medical center, which is about six minutes away. You have the airway equipment and take it out of the plastic pouch. Tubes come in all sizes with different variations for infants and adults. You get the blade ready to move the tongue away. There is a mask on his face to squeeze for breathes. You tell them to stop "bagging." You can see his mouth in front of you. You know he needs to get to the hospital as quickly as possible.

You have your blade, light, and tube to proceed. You are crouched on a knee with your head down. You place the blade down inside his mouth. You can see the blade going along the tongue into the throat. You're trying to pull up the tongue to get the airway open. You can't see the trachea. You continue to lift up, but you can't see the chords to insert the tube. You can't gain access to the lungs. You see the redness and moisture in his mouth; blood is circulating and he is probably still alive. You know where the chords are supposed to be, but you can't find them. You don't want to accidentally blow air into his esophagus because it will reach his stomach. You try again using the same procedure, but you can't see the chords. You take over bagging him. You hear the siren outside and see the bright lights.

The truck pulls up to the hospital. The back door opens and you walk into the entrance. You see people on the right in your periphery. A nurse enters the code on the keypad. You walk in the ER and see the workstation desk area. Nurses and personnel are stationed behind the desk. You move to the trauma bay on the right and see six beds. You put the boy down near the end and you step back. You see the hospital personnel step into action-they've been waiting to receive him. You can't see his head anymore. His legs are moving from the force of CPR. You watch for a few seconds.`,
};

const Component = () => {
    return (
        <BuilderBlock title="Configure Text">
            <Field
                name="defaultText"
                component={CccisdTextarea}
                label="Default Text"
                style={{ height: '300px' }}
            />
        </BuilderBlock>
    );
};

// reportTemplateBuilder is a Formik wrapper which combines the Fields in this component with Filter and Widget Fields.
export default reportTemplateBuilder({})(Component);
