import React from 'react';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';

const Register = () => {
    const pageProps = {
        registerProps: {
            role: 'learner',
            showPasswordFields: true,
            validateAdditionalFields: vals => {
                const errs = {};
                if (!vals.first_name) {
                    errs.first_name = 'First name is required.';
                }
                if (!vals.last_name) {
                    errs.last_name = 'Last name is required.';
                }
                return errs;
            },
        },
    };

    return <RegisterLoginPage {...pageProps} />;
};

export default Register;
