import React from 'react';
import _find from 'lodash/find';
import IconHome from 'cccisd-icons/home2';
import { findNav } from 'cccisd-laravel-appdefs';
import AuthRedirect from '../AuthRedirect';
import { Link } from 'cccisd-react-router';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export default class Component extends React.Component {
    render() {
        let nav = findNav(AppDefs.navs, AppDefs.routes);
        let homepageRoute = nav ? _find(AppDefs.routes, r => r.handle === nav.homepage) : false;

        if (!Fortress.auth()) {
            return <AuthRedirect />;
        }

        return (
            <div className="not-found">
                <h2>404 ERROR</h2>
                <div style={{ marginBottom: '1em' }}>
                    Sorry, an error has occurred. Requested page not found!
                </div>
                {Fortress.auth() && homepageRoute && (
                    <Link className="btn btn-primary" to={homepageRoute.url}>
                        <IconHome spaceRight />
                        Take Me Home
                    </Link>
                )}
            </div>
        );
    }
}
